import React, { useRef, useEffect, useCallback, Fragment } from 'react'
import { useInView, motion, useAnimation } from 'framer-motion';
// import { useTheme } from '@emotion/react';
import { alpha } from '@mui/material';
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import useTheme from '@mui/material/styles/useTheme';

const topMarginOneText = 10
const bottomMarginOneText = 10
const topPaddingOneText = 10
const bottomPaddingOneText = 10
const allYMarginsOneText = topMarginOneText + bottomMarginOneText + topPaddingOneText + bottomPaddingOneText
const maxWidthCalculated = 300
const rxMax = maxWidthCalculated / 2
const ryMax = maxWidthCalculated / 2

const rxAround = 16
const ryAround = 19
const xCenter = rxMax - 4 * rxAround //- j * 40;
const yCenter = -ryMax //- j * 40;

const heightOneText = 100 + allYMarginsOneText

const heightOneTextCurrent = maxWidthCalculated + 2 * ryAround + allYMarginsOneText

function getPositionsOfWords(sentence) {
    const words = sentence.split(" ");
    let currentIndex = 0;
    return words.map(word => {
        const index = sentence.indexOf(word, currentIndex);
        currentIndex = index + word.length;
        return index;
    });
}

function calculatePointsOnEllipse(rx, ry, centerX, centerY, n, fullAngle = 360) {
    const points = [];
    const angleIncrement = (fullAngle / 180 * Math.PI) / n; // Full angle divided by n

    for (let i = 0; i < n; i++) {
        const angle = i * angleIncrement;
        const x = rx * Math.cos(angle);
        const y = ryMax + ry * Math.sin(angle);
        points.push({ x, y });
    }

    return points;
}

const varDivCharAnim = {

    charXYIntoCircle: ({ i, j, index, isFirst, xCirc, yCirc, rotZ, xCircHalf, yCircHalf, itemsNumber }) => {
        // const rotZ90 = rotZ - 90
        return ({
            // rotateZ: `${rotZ}deg`, // "0deg", //rotZ,
            x: xCirc,
            y: yCirc,
            textTransform: "none",
            // fontSize: "14px",

            transition: {
                duration: 1.3,
                delay: (itemsNumber - i) * 0.03,

            },
        })
    },
    charXYIntoCircleHalf: ({ i, j, index, isFirst, xCirc, yCirc, rotZ, xCircHalf, yCircHalf, itemsNumber }) => ({
        x: xCircHalf, // [null, xCircHalf, xCirc, xCircHalf], // xCircHalf, // [null, xCircHalf, xCircHalf / 4], //xCircHalf, // [null, xCircHalf, xCircHalf + 90],
        y: yCircHalf, // [null, yCircHalf, yCirc, yCircHalf], // yCircHalf, // fontSize: "8px",
        rotateZ: `${rotZ}deg`,
        transition: {
            duration: 1.3,
            // delay: i * 0.03,
        },
    }),
    charXYIntoCircleSimple: ({ i, j, index, isFirst, xCirc, yCirc, rotZ, xCircHalf, yCircHalf, itemsNumber }) => ({
        x: xCirc,
        y: yCirc,
        transition: {
            duration: 1.3,
            delay: (itemsNumber - i) * 0.03,
        },
    }),

    setAbsXCircYCircRotZ: ({ i, j, index, isFirst, xCirc, yCirc, rotZ, itemsNumber }) => ({
        position: "absolute",
        x: xCirc,
        y: yCirc,
        textTransform: "none",
        rotateZ: `${rotZ}deg`,
        // rotateZ: rotZ`,
    }),

    setStatic: {
        position: "static",
        textTransform: "none",
    },


    x0yEndRelative: ({ i, j, index, isFirst, xCirc, yCirc, rotZ, itemsNumber }) => ({

        y: j * 50,
        x: 0,
        transitionEnd: {
            position: "relative",
        },
        transition: {
            duration: 1,
            delay: (itemsNumber - i) * 0.03,

        }
    }),
    mimicXstaticInAbsolute: ({ i, j, index, isFirst, xCirc, yCirc, rotZ, itemsNumber }) => ({
        // y: (index + 1 + j) * 15, // +ryMax,
        // x: 0,
        // x: (i - itemsNumber / 2) * 10,
        y: [ null, j * 15 ], // [null, Math.sin(i * 20 * Math.PI / itemsNumber) + j * 15], // 
        x: (i + 1) * 10,

        transition: {
            duration: 1,
            delay: (itemsNumber - i) * 0.03,
            // y: {
            //     type: "spring",
            //     damping: 3,
            //     stiffness: 100,
            // }
        }
    }),

    xCircYCircRotZ: ({ i, j, index, isFirst, xCirc, yCirc, rotZ, itemsNumber }) => ({
        x: xCirc,
        y: yCirc,
        rotateZ: rotZ,
        transition: {
            delay: i * 0.03,
            duration: 1,
        },
    }),
    x0y0rotZ0: ({ i, j, index, isFirst, xCirc, yCirc, rotZ, itemsNumber }) => ({
        x: 0,
        // // x:(i-itemsNumber/2)*10,
        y: 0,
        rotateZ: 0,
        transition: {
            duration: 1,
            delay: i * 0.03,

        }
    }),

    rotZ0rotZ: ({ i, j, index, isFirst, xCirc, yCirc, rotZ, itemsNumber }) => ({
        rotateZ: [ `${rotZ}deg`, "0deg", `${rotZ}deg` ], // [null, "0", `${rotZ}`], // [null, "0deg", `${rotZ}deg`], // "0deg", // ,
        transition: {
            repeat: 1,
            // repeatType: "reverse",
            // duration: 0.5,
            delay: i * 0.03,
        }
    }),

    font30TextTransfXCircPlus30Rev: ({ i, j, index, isFirst, xCirc, yCirc, rotZ, itemsNumber }) => ({
        textTransform: isFirst ? "uppercase" : "lowercase",
        fontSize: "30px",
        x: xCirc + 30,
        transition: {
            duration: 1,
            repeat: 1,
            repeatType: "reverse",
            delay: i * 0.03 + j * 1,
        }
    }),
    textTransformNoneRev: ({ i, j, index, isFirst, xCirc, yCirc, rotZ, itemsNumber }) => ({
        textTransform: "none",
        transition: {
            duration: 0.2,
            repeat: 1,
            delay: i * 0.03,
            repeatType: "reverse",
        }
    }),

    y0xXCenterRotX90rev: ({ i, j, index, isFirst, xCirc, yCirc, rotZ, itemsNumber }) => ({
        // originY: "10%",
        // originX: "10%",
        // originZ: 50,
        y: ryMax, // 0, // 2 * ryMax, // 0, // yCenter, //"50%", // 50,
        x: 0, // rxMax, // 0, // rxMax,// 0, // xCenter, // "50%", //" 50,
        // rotateX: 90, // 180,
        // rotateZ: [ null, rotZ + 180, rotZ, rotZ + 180, rotZ, rotZ + 180, rotZ ],
        transition: {
            duration: 0.5,
            repeatType: "reverse",
            repeat: 1,
            delay: i * 0.03 + j * 1,
        }

    }),

}


// const makeOnePathFromPathArray = (arrPath = []) => {
//     const pathBig = arrPath.reduce((prev, curr, i, arr) => {
//         if (i > 0) curr.replace("M", "L")
//         const corr = i > 0 ? curr.replace("M", "L") : curr
//         const merged = `${prev} ${corr}`
//         return merged
//     }, "")

//     return pathBig
// }


// const paths300V = [
//     'M 0,0 C 60,150 0,150 30,300',
//     'M 60,300 C 30,150 90,150 60,0',
//     'M 90,0 C 120,150 60,150 90,300',
//     'M 120,300 C 90,150 150,150 120,0',
//     'M 150,0 C 180,150 130,150 300,300',
// ]


// const bigPath300V = makeOnePathFromPathArray(paths300V)

// const path = 'M 0 0 L 100 0 L 50 100 Z';
const dUhloprieckaDown = "M 10 10 L 90 90"
// const dUhloprieckaUp = "M 10 90 L 90 10"
// const dWave430_180 = "M 50 80 C 150 -20 250 180 350 80"

const sxWrap = {
    //   bgcolor: "primary.dark",
    // color:"primar.contrastText",
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    // flexDirection: 'column',
    // transformOrigin: "50% 50% 0px",
    position: "relative",
    width: `${maxWidthCalculated + 2 * rxAround}px`,

    maxWidth: "100%",

    marginLeft: "auto",
    marginRight: "auto",
    // borderStyle: 'dotted',
    // px: [ 3, 4, 5, 6 ],
    px: 0,
    py: [ 1, 2, 3 ],
    // "& h4": {
    //     // fontSize: [ "1.0rem", "1.2rem", "1.5rem" ],
    //     fontSize: [ "small", "small", "medium" ],
    //     mb: [ 1, 2, 3 ],
    // },
    "& .charInCircle": {

    },
    "& .divs": {
        width: "100%",
    }

}

const textW1 = "Budem často tvoriť obsah, pridávať blogové príspevky na prečítanie, s obrázkami, videami.|Potrebujem webstránky vo WordPresse alebo v Joomla."
const textNeu = "Stačí mi pár statických stránok, neplánujem ich meniť, ani nič pravidelne pridávať.|Webstránky nemusia byť vytvorené ani vo Wordpresse alebo v Joomla, a vôbec nie v Reacte."
const textR1 = "Moje webstránky by mali zvládať aj pridanie rôznej logiky, pridanie interaktívnych prvkov.|Ak sa logika a interaktívne prvky dá dosiahnuť dostupnými funkciami alebo pluginmi vo Wordpresse alebo Joomle, tak ich netreba vytvárať s React.js."
const textR2 = "Stačí mi pár stránok, a na nich sa budú načítavať dynamicky sa meniace dáta.|Webstránky možno vytvoriť s React.js."
const textR3 = "Veľmi dôležitá je rýchlosť mojich komplexných webstránok.| Rýchle webstránky sa dajú vytvoriť aj vo Wordpresse alebo Joomle, nielen v Reacte alebo s Angular."
const textR4 = "Chcem často tvoriť obsah, pridávať blogové príspevky na prečítanie, a príspevkov bude 50 000 a viac.|React.js je dobrá voľba.."
// const textR5 = "Ak sa funkčnosť web stránky dá dosiahnuť vo wordpresse alebo Joomle, tak ich netreba vytvárať s React.js."

const arrDefaultRawTexts = [
    textW1, textNeu, textR1, textR2, textR3, textR4
]




const CircleZ = ({ arrRawTexts = arrDefaultRawTexts }) => {
    // console.log("CircleZ start =========================================================")

    // // const { notInView } = useContext(NotInViewContext);
    // console.log("CircleZ notInView", notInView)

    const isMounted = useRef(false);
    const refCurrIndex = useRef(0)
    const refWrap = useRef(null)
    const inView = useInView(refWrap)
    const refIsFirstQuickAnimationAlreadyRun = useRef(false)

    const numberOfTexts = arrRawTexts.length
    // const yShift = (numberOfTexts - 1) * heightOneText //255
    const yShift = (numberOfTexts) * heightOneText //255


    const ctlDivCharAnim = useAnimation()
    const ctlOneText = useAnimation()
    const ctlOneSentence = useAnimation()

    const ctlCharSvgPathAnim = useAnimation()
    const ctlCharSvgStarPathAnim = useAnimation()

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        }
    }, [])

    const theme = useTheme()
    const svgPathFillCol = theme.palette.secondary.main
    const svgPathCol = theme.palette.secondary.contrastText

    const stopSeq1 = useCallback(async () => {
        // console.log("CircleZ stopSeq1 start")
        refCurrIndex.current = 0
        ctlCharSvgStarPathAnim.stop()
        ctlCharSvgPathAnim.stop()
        ctlDivCharAnim.stop()
        ctlOneSentence.stop()
        ctlOneText.stop()
        ctlOneText.set(({ index }) => ({
            top: index === 0 ? "0px" : `${ryAround + heightOneTextCurrent + index * heightOneText - allYMarginsOneText / 2}px`,
            height: index === 0 ? `${heightOneTextCurrent}px` : "auto",
            rotateZ: index === 0 ? "270deg" : "0deg",
        }))
        ctlOneSentence.set(({ index, j }) => ({
            skewY: 0,
            skewX: 0,
            position: index === 0 ? "absolute" : "static",
            height: index === 0 ? "inherit" : "auto",
            rotateZ: "0deg",
        }))
        ctlDivCharAnim.set(({ i, j, index, isFirst, xCirc, yCirc, rotZ, itemsNumber }) => {
            return index === 0 ? {
                x: xCirc,
                y: yCirc,
                rotateZ: `${rotZ}deg`, // "0deg", //rotZ,

                textTransform: "none",
                // fontSize: "14px",
                position: "absolute",  // x:0,y:0,

            } : {
                position: "static",
                textTransform: "none",
                // fontSize: "14px",
                rotateZ: "0deg", // `${rotZ}deg`, // "0deg", //rotZ,
                x: 0,
                y: 0,
            }
        }
        )

    }, [ ctlCharSvgPathAnim, ctlCharSvgStarPathAnim, ctlDivCharAnim, ctlOneText, ctlOneSentence ]) //svgPathFillCol

    const seqBeforeSeq1 = useCallback(async () => {

        ctlOneSentence.set(({ index }) => {
            return index === refCurrIndex.current ? {
                position: "relative",
                // offsetPath: 'unset',
                // y: yCenter,
                height: "auto",
                // originY: "50%",
                // originX: "50%",
            } : {
                position: "relative",
                height: "inherit",
            }
        })

        isMounted.current && await ctlDivCharAnim.start(({ i, j, index, isFirst, xCirc, yCirc, rotZ, itemsNumber }) => index === 0 ? "mimicXstaticInAbsolute" : {})
        isMounted.current && await ctlDivCharAnim.start(({ i, j, index, isFirst, xCirc, yCirc, rotZ, itemsNumber }) => index === 0 ? "x0yEndRelative" : {})

        isMounted.current && await ctlCharSvgPathAnim.start(({ i }) => ({
            pathLength: 0,
            fill: alpha(svgPathFillCol, 0),
            transition: {
                duration: 0.2,
                delay: i * 0.03,
            }
        }))

    }, [ ctlCharSvgPathAnim, ctlDivCharAnim, ctlOneSentence, svgPathFillCol ])

    const endSeq1 = useCallback(async () => {
        isMounted.current && await ctlOneSentence.start(({ j, index }) => ({
            skewX: "0deg",
            skewY: "0deg",
            transition: {
                delay: index * 0.3 + j * 0.15,
                duration: 0.2,
            }
        })
        )
        isMounted.current > 0 && await ctlOneText.start(({ index }) => ({
            top: `${ryAround + index * heightOneText}px`,
            // rotateZ: index === 0 ? "270deg" : "0deg",
            transition: {
                duration: 0.5,
                delay: index * 0.1,
                ease: "easeInOut",
            }
        }))
    }, [ ctlOneText, ctlOneSentence ])

    const seq1 = useCallback(async () => {
        // console.log("CircleZ seq1")
        if (refCurrIndex.current === 0) {
            isMounted.current && await ctlDivCharAnim.start(({ j, i, index, itemsNumber, xCirc, yCirc, rotZ }) => refCurrIndex.current === index ? "rotZ0rotZ" : {})

            isMounted.current && await ctlDivCharAnim.start(({ j, i, index, itemsNumber, xCirc, yCirc, xCircHalf, yCircHalf, rotZ }) => index === refCurrIndex.current ? "charXYIntoCircleHalf" : {})
            isMounted.current && ctlOneText.start(({ index }) => index === refCurrIndex.current ? { rotateZ: "180deg", transition: { duration: 0.5, repeatType: "reverse", repeat: 1 } } : {})

            isMounted.current && await ctlDivCharAnim.start(({ j, i, index, itemsNumber, xCirc, yCirc, xCircHalf, yCircHalf, rotZ }) => index === refCurrIndex.current ? "charXYIntoCircleSimple" : {})
        } else {
            isMounted.current && await ctlDivCharAnim.start(({ j, i, index, itemsNumber, xCirc, yCirc, xCircHalf, yCircHalf, rotZ }) => index === refCurrIndex.current - 1 ? "charXYIntoCircleHalf" : {})
            isMounted.current && ctlOneText.start(({ index }) => index === refCurrIndex.current - 1 ? { rotateZ: "180deg", transition: { duration: 0.5, repeatType: "reverse", repeat: 1, type: "spring", damping: 1, stiffness: 200, } } : {})
            isMounted.current && await ctlDivCharAnim.start(({ j, i, index, itemsNumber, xCirc, yCirc, xCircHalf, yCircHalf, rotZ }) => index === refCurrIndex.current - 1 ? "charXYIntoCircleSimple" : {})
        }

        isMounted.current && ctlOneSentence.set(({ index }) => {
            return index === refCurrIndex.current ? {
                position: "absolute",

            } : {
                position: "static", //" "relative",
            }
        })


        isMounted.current && refCurrIndex.current > 0 && ctlOneSentence.set(({ index }) => {
            return index === refCurrIndex.current ? {
                position: "relative",
                // offsetPath: 'unset',
                // y: yCenter,
                height: "auto",
                // originY: "50%",
                // originX: "50%",
            } : index === refCurrIndex.current - 1 ? {
                position: "absolute",
                height: "inherit",
            } : {}
        })


        isMounted.current && refCurrIndex.current > 0 && await ctlDivCharAnim.start(({ i, j, index, isFirst, xCirc, yCirc, rotZ, itemsNumber }) => index === refCurrIndex.current - 1 ? "mimicXstaticInAbsolute" : {})
        isMounted.current && refCurrIndex.current > 0 && await ctlDivCharAnim.start(({ i, j, index, isFirst, xCirc, yCirc, rotZ, itemsNumber }) => index === refCurrIndex.current - 1 ? "x0yEndRelative" : {})
        isMounted.current && refCurrIndex.current > 0 && await ctlOneSentence.set(({ j, index, }) => index === refCurrIndex.current - 1 ? { position: "static", height: "auto" } : {})

        ctlDivCharAnim.set(({ index }) => refCurrIndex.current === index ? { position: "absolute" } : { position: "relative" })

        isMounted.current && refCurrIndex.current > 0 && await ctlDivCharAnim.start(({ j, i, index, itemsNumber, xCirc, yCirc, rotZ }) => index === refCurrIndex.current ? "charXYIntoCircle" : {})

        isMounted.current && refCurrIndex.current > 0 && await ctlOneText.start(({ index }) => {
            const indexShift = index > refCurrIndex.current ? 1 : 0
            const animation = refCurrIndex.current === index ? {
                // originY: "unset",
                // originX: "unset",
                // left: "50%",
                top: "0px",  //  `${-2 * ryAround + heightOneTextCurrent / 2}px`,
                height: `${heightOneTextCurrent}px`,
                rotateZ: "270deg",

                transition: {
                    duration: 0.3,
                }
            } : {
                // originY: "unset",
                // originX: "unset",
                // left: "0%",
                height: "auto",
                // top: `${ryAround + heightOneTextCurrent + (index - indexShift) * heightOneText}px`,
                top: `${ryAround + heightOneTextCurrent + index * heightOneText}px`,
                rotateZ: "0deg",

                transition: {
                    duration: 0.3,
                }
            }
            return ({ ...animation })
        })

        isMounted.current && refCurrIndex.current > 0 && await ctlDivCharAnim.start(({ j, i, index, itemsNumber, xCirc, yCirc, rotZ }) => refCurrIndex.current === index ? "xCircYCircRotZ" : "x0y0rotZ0")

        isMounted.current && await ctlDivCharAnim.start(({ j, i, index, itemsNumber, xCirc, yCirc, rotZ }) => refCurrIndex.current === index ? "rotZ0rotZ" : {})

        isMounted.current && await ctlDivCharAnim.start(({ index }) => refCurrIndex.current === index ? "font30TextTransfXCircPlus30Rev" : "textTransformNoneRev")

        isMounted.current && await ctlCharSvgPathAnim.start(({ i, index, itemsNumber }) => {
            const animation = refCurrIndex.current === index ? {
                pathLength: 1,
                transition: {
                    duration: 0.2,
                    delay: i * 0.03,
                }
            } : {}
            return ({ ...animation })
        })

        isMounted.current && await ctlCharSvgPathAnim.start(({ i, index, itemsNumber }) => {
            const animation = refCurrIndex.current === index ? {

                fill: alpha(svgPathFillCol, 1),
                transition: {
                    duration: 0.5,
                    delay: i * 0.03,
                }
            } : {
                fill: alpha(svgPathFillCol, 0),
                transition: {
                    duration: 0.5,
                    delay: i * 0.03,
                }
            }
            return ({ ...animation })
        })

        isMounted.current && await ctlCharSvgPathAnim.start(({ i, index, itemsNumber }) => {
            const animation = refCurrIndex.current === index ? {
                fill: alpha(svgPathFillCol, 0),
                transition: {
                    duration: 0.5,
                    delay: (itemsNumber - i) * 0.02,
                }
            } : {}
            return ({ ...animation })
        })

        isMounted.current && await ctlCharSvgPathAnim.start(({ i, index, itemsNumber }) => {
            const animation = refCurrIndex.current === index ? {
                pathLength: 0,
                transition: {
                    duration: 0.3, // 1.2,
                    delay: i * 0.03,
                }
            } : {}
            return ({ ...animation })
        })

        isMounted.current && await ctlCharSvgStarPathAnim.start(({ i, index, itemsNumber }) => {
            const animation = refCurrIndex.current === index ? {
                pathLength: 1,
                transition: {
                    duration: 0.5,
                    delay: i * 0.03,
                }
            } : {}
            return ({ ...animation })
        })

        isMounted.current && await ctlCharSvgStarPathAnim.start(({ i, index, itemsNumber }) => {
            const animation = refCurrIndex.current === index ? {
                fill: alpha(svgPathFillCol, 1),
                transition: {
                    duration: 0.5,
                    delay: i * 0.03,
                }
            } : {
                fill: alpha(svgPathFillCol, 0),
                transition: {
                    duration: 0.5,
                    delay: i * 0.03,
                }
            }
            return ({ ...animation })
        })

        isMounted.current && await ctlDivCharAnim.start(({ i, j, index, isFirst, xCirc, yCirc, rotZ, itemsNumber }) => refCurrIndex.current === index ? "y0xXCenterRotX90rev" :
            {} //  undefined // {}
        )

        isMounted.current && await ctlOneSentence.start(({ j, index }) => {
            const evenFactor = index % 2 === 0 ? 1 : -1
            const animation = refCurrIndex.current === index ? {
                // rotateZ: [ null, 180, -180, 0 ],
                rotateZ: [ "0deg", "180deg", "-180deg", "0deg" ],
                skewX: [ null, 20, -20, `${evenFactor * 10}deg` ],
                skewY: [ null, 20, -20, `${evenFactor * 10}deg` ],
                transition: {
                    delay: index * 0.3 + j * 0.15,
                    duration: 2,
                    // // repeatType: "reverse",
                    // // repeatDelay: 0.3,
                    // // repeat: 1,
                    // type: "spring",
                    // damping: 1,
                    // stiffness: 200,
                }
            } : {
                skewX: j === 0 ? `${360 - evenFactor * 20}deg` : `${evenFactor * 10}deg`,
                skewY: j === 0 ? `${evenFactor * 10}deg` : `${evenFactor * 11}deg`,
            }
            return ({ ...animation })
        })

        isMounted.current && await ctlCharSvgStarPathAnim.start(({ i, index, itemsNumber }) => {
            const animation = refCurrIndex.current === index ? {
                fill: alpha(svgPathFillCol, 0),
                transition: {
                    duration: 0.5,
                    delay: (itemsNumber - i) * 0.02,
                }
            } : {}
            return ({ ...animation })
        })


        isMounted.current && await ctlCharSvgStarPathAnim.start(({ i, index, itemsNumber }) => {
            const animation = refCurrIndex.current === index ? {
                pathLength: 0,
                transition: {
                    duration: 0.3, // 1.2,
                    delay: i * 0.03,
                }
            } : {}
            return ({ ...animation })
        })

    }, [ ctlDivCharAnim, ctlCharSvgPathAnim, ctlCharSvgStarPathAnim, ctlOneSentence, ctlOneText, svgPathFillCol ]) // indexItem

    const handleTap = useCallback(async () => {
        await stopSeq1()
        refCurrIndex.current = 0
        // isMounted.current && await seqBeforeSeq1()
        for (let k = 0; k < numberOfTexts; k++) {

            isMounted.current && await seq1()
            refCurrIndex.current += 1
        }
        isMounted.current && await endSeq1()

    }, [ numberOfTexts, stopSeq1, seq1, endSeq1 ]) //seqBeforeSeq1, 

    useEffect(() => {
        let isMount = true
        // if (!isMounted.current) return
        const stopControls = () => {
            ctlDivCharAnim.stop()
            ctlCharSvgPathAnim.stop()
            ctlCharSvgStarPathAnim.stop()
            ctlOneSentence.stop()
            ctlOneText.stop()
        }
        if (!inView) {
            stopControls()
            return
        }
        if (refIsFirstQuickAnimationAlreadyRun.current) {

            // isMounted.current && handleTap()
            isMount && handleTap()
        } else {

            async function initialAnimation() {

                isMount && await ctlOneText.start(({ index }) => index === 0 ? {
                    top: "0px",
                    height: `${heightOneTextCurrent}px`,
                    rotateZ: "270deg",
                    transition: {
                        duration: 0.3,
                    }
                } : {})

                isMount && ctlOneSentence.set(({ j, index }) => index === 0 ? {
                    position: "absolute",
                    height: "inherit",
                } : {
                    position: "static",
                    height: "auto",
                })

                isMount && ctlDivCharAnim.set(({ i, j, index, isFirst, xCirc, yCirc, rotZ, itemsNumber }) => index === 0 ? {
                    position: "absolute",
                    textTransform: "none",
                    x: 0, y: ryMax,
                    rotateZ: "0deg",
                } : {
                    position: "static",
                    textTransform: "none",
                })
                isMount && await ctlDivCharAnim.start(({ i, j, index, isFirst, xCirc, yCirc, rotZ, itemsNumber }) => index === 0 ? {
                    // position: "absolute",
                    x: xCirc, // [ 0, xCirc, xCirc ], // xCirc,
                    y: yCirc, // [ 0, yCirc, yCirc ], // yCirc,
                    rotateZ: `${rotZ}deg`,
                    // textTransform: "none",
                    // rotateZ: [ "0deg", "0deg", `${rotZ}deg` ], // `${rotZ}deg`,
                    transition: {
                        duration: 0.1,
                        // delay: 0.1 * i,

                    }
                    // rotateZ: rotZ`,
                } : {})
            }

            initialAnimation();
            refIsFirstQuickAnimationAlreadyRun.current = true
        }

        return () => {
            isMount = false
            stopControls()
        }
    }, [ ctlDivCharAnim, ctlCharSvgPathAnim, ctlCharSvgStarPathAnim, ctlOneSentence, ctlOneText, inView, handleTap ])


    return (<>
        <Typography variant="h4" align="center" sx={{
            fontSize: [ "small", "small", "medium" ],
            pt: [ 2, 3 ],
            pb: [ 1, 2, 3 ],
        }}>
            Webstránky s React.js alebo s Wordpress / Joomla ?
        </Typography>
        <Box
            // className="clay"
            sx={sxWrap}
        >
            <motion.div //Reorder.Group
                ref={refWrap}
                // as="div"
                // axis="y"
                //   onReorder={setTabs}
                layout

                className="divs"
                style={{
                    position: "relative",
                    width: "inherit",
                    maxWidth: "inherit",
                    // width: "100%",
                    // width:`${heightOneTextCurrent}px`,
                    height: `${maxWidthCalculated + 2 * ryAround + yShift}px`,
                    textAlign: "center",
                    cursor: "pointer",
                    // paddingInlineStart: 0,
                    // listStyleType: "decimal",
                    // borderStyle: 'solid'
                }}
                onTap={handleTap}
                values={arrRawTexts}
                key={`divs`}
            >

                {arrRawTexts.map((rawText, index) => {
                    const arrSentence = rawText.split("|")

                    return (
                        <Box component={motion.div}   // Reorder.Item
                            layout
                            animate={ctlOneText}
                            custom={{ index }}
                            initial={false}
                            className="oneText"
                            sx={{
                                marginTop: `${topMarginOneText}px`,
                                marginBottom: `${bottomMarginOneText}px`,
                                paddingTop: `${topPaddingOneText}px`,
                                paddingBottom: `${bottomPaddingOneText}px`,
                                transformOrigin: "50% 50% 0px",
                                position: "absolute",
                                width: "inherit",
                                maxWidth: "inherit",
                                // backgroundColor: "primary.main",
                                // color: "primary.contrastText",
                                // width: "100%",
                                left: 0, // "10px",
                                top: `${ryAround + heightOneTextCurrent + index * (heightOneText) - allYMarginsOneText / 2}px`,
                                height: "auto",
                                rotateZ: "0deg",

                            }}
                            key={`oneText-${index}`}
                        >

                            {arrSentence.map(((sent, j) => {
                                return (
                                    <Sentence
                                        sent={sent}
                                        j={j}
                                        ctlOneSentence={ctlOneSentence}
                                        index={index}
                                        inView={inView}
                                        varDivCharAnim={varDivCharAnim}
                                        ctlDivCharAnim={ctlDivCharAnim}
                                        svgPathFillCol={svgPathFillCol}
                                        ctlCharSvgPathAnim={ctlCharSvgPathAnim}
                                        ctlCharSvgStarPathAnim={ctlCharSvgStarPathAnim}
                                        svgPathCol={svgPathCol}
                                        key={`sent-${j}`}
                                    />)

                            }))}
                        </Box>
                    )

                })}
            </motion.div>
        </Box>
    </>
    );
};

export default CircleZ



const Sentence = ({ sent, j, ctlOneSentence, index, inView, varDivCharAnim, ctlDivCharAnim, svgPathFillCol, ctlCharSvgPathAnim, ctlCharSvgStarPathAnim, svgPathCol }) => {
    // console.log("CircleZ sent, j, ctlOneSentence, index, notInView, varDivCharAnim, ctlDivCharAnim, svgPathFillCol, ctlCharSvgPathAnim, ctlCharSvgStarPathAnim, svgPathCol", sent, j, ctlOneSentence, index, notInView, varDivCharAnim, ctlDivCharAnim, svgPathFillCol, ctlCharSvgPathAnim, ctlCharSvgStarPathAnim, svgPathCol)
    const sentTrimmed = sent.trim();
    const firstPositions = getPositionsOfWords(sentTrimmed);
    const rx = rxMax - j * 40;
    const ry = ryMax - j * 40;
    const itemsNumber = sentTrimmed.length;
    const unitAngle = 360 / itemsNumber;

    const rxTotal = rxMax + rxAround / 2 - j * 40; //+ rxAround / 4 
    const ryTotal = ryMax + rxAround / 2 - j * 40; //+ ryAround / 4 

    const points = calculatePointsOnEllipse(rxTotal, ryTotal, xCenter, yCenter, itemsNumber, 360);
    const pointsHalf = calculatePointsOnEllipse(rxTotal, ryTotal, xCenter, yCenter, itemsNumber, 180);
    const shiftAngle = unitAngle + 90

    return (
        <motion.div
            data-description={sentTrimmed}
            layout
            animate={ctlOneSentence}

            initial={false}
            style={{
                width: "inherit",
                height: "auto", // "inherit",
                transformOrigin: "inherit", // offsetDistance: "0%",
            }}

            custom={{ index, j }}
            className="oneSentence clay"
            key={`oneSentence-${index}-${j}`}
            style={{ marginTop: "10px", marginBottom: "10px" }}
        >
            {
                Array.from(sentTrimmed).map((char, i) => {
                    return (
                        <Character
                            point={points[ i ]}
                            pointHalf={pointsHalf[ i ]}
                            firstPositions={firstPositions}
                            i={i}
                            rx={rx}
                            ry={ry}
                            itemsNumber={itemsNumber}
                            unitAngle={unitAngle}
                            inView={inView}
                            varDivCharAnim={varDivCharAnim}
                            j={j}
                            index={index}
                            ctlDivCharAnim={ctlDivCharAnim}
                            char={char}
                            svgPathFillCol={svgPathFillCol}
                            ctlCharSvgPathAnim={ctlCharSvgPathAnim}
                            ctlCharSvgStarPathAnim={ctlCharSvgStarPathAnim}
                            svgPathCol={svgPathCol}
                            key={`char-${index}-${j}-${i}`}
                        />
                    )
                })
            }
        </motion.div>
    );
}

const Character = ({ point, pointHalf, firstPositions, i, rx, itemsNumber, ry, unitAngle, inView, varDivCharAnim, j, index, ctlDivCharAnim, char, svgPathFillCol, ctlCharSvgPathAnim, ctlCharSvgStarPathAnim, svgPathCol }) => {
    const isFirst = firstPositions.includes(i);

    const xCirc = point.x
    const yCirc = point.y
    const xCircHalf = pointHalf.x
    const yCircHalf = pointHalf.y
    const rotZ = Math.round(unitAngle * i) + 90;

    return (
        <Box component={motion.div}
            layout
            variants={varDivCharAnim}
            animate={ctlDivCharAnim}
            initial={false}
            custom={{ i, j, index, isFirst, xCirc, yCirc, rotZ, xCircHalf, yCircHalf, itemsNumber }}
            className="charInCircle"
            sx={{
                lineHeight: 'initial',
                transformOrigin: "inherit", // offsetDistance: "0%",
                fontSize: [ "0.7rem", "0.8rem" ],
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                // zIndex: 2,

            }}
            key={`char-${index}-${j}-${i}`}
        >


            <Fragment>
                {isFirst && <motion.svg
                    viewBox="0 0 100 100"
                    width="25" height="25"

                    style={{
                        fill: "none",
                        position: "absolute",
                        zIndex: -1,
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    key="svg1"
                >

                    <motion.rect
                        initial={false}
                        animate={ctlCharSvgPathAnim}
                        custom={{ i, index, itemsNumber }}
                        pathLength={0}
                        fill={alpha(svgPathFillCol, 0)}
                        width="100"
                        height="100"
                        // fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth='2'
                        stroke='aqua'
                        key="rect"
                    ></motion.rect>
                    <motion.circle
                        initial={false}
                        animate={ctlCharSvgPathAnim}
                        custom={{ i, index, itemsNumber }}

                        cx="50"
                        cy="50"
                        r="50"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth='2'
                        stroke="green"
                        style={{
                            pathLength: 1,
                            // fill: alpha(svgPathFillCol, 0.7),
                            fill: "saddlebrown", // 'var(--color-success-main)'
                        }}
                        key="circ"
                    ></motion.circle>
                    <motion.polygon
                        points="0,100 50,0 100,100"
                        initial={false}
                        animate={ctlCharSvgPathAnim}
                        custom={{ i, index, itemsNumber }}

                        style={{
                            pathLength: 0, fill: alpha(svgPathFillCol, 0),
                            stroke: "purple",
                            strokeWidth: 1
                        }}
                        key="poly" />
                    <motion.path
                        animate={ctlCharSvgPathAnim}
                        custom={{ i, index, itemsNumber }}

                        d={dUhloprieckaDown}
                        stroke="red"
                        strokeWidth="2"
                        initial={false}
                        style={{
                            pathLength: 1,
                            fill: alpha(svgPathFillCol, 1)
                        }}
                        key={`pathUhlo`} />
                </motion.svg>}
                {isFirst &&
                    <svg
                        viewBox="0 0 24 24"
                        width="36" height="36"
                        style={{
                            fill: "none",
                            position: "absolute",
                            zIndex: -1,
                        }}
                        key="svgStar"
                    >
                        <motion.polygon
                            points="12 4 9.22 9.27 3 10.11 7.5 14.21 6.44 20 12 17.27 17.56 20 16.5 14.21 21 10.11 14.78 9.27 12 4"
                            initial={false}
                            animate={ctlCharSvgStarPathAnim}
                            custom={{ i, index, itemsNumber }}
                            style={{
                                pathLength: 0, fill: alpha(svgPathFillCol, 0.2),
                                stroke: svgPathCol,
                                strokeLinecap: "round",
                                strokeLinejoin: "round",
                                strokeWidth: 0.25,
                            }}
                            key="polyStar" />
                    </svg>}
            </Fragment>
            {char === " " ? "\u00A0" : char}

        </Box>
    );
}
